<template>
  <div class="about-us">
    <!-- 公司图片简介 -->
    <div class="introduction-image">
      <img src="@/image/about-us/img_banner_us@3x.png" alt="" />
    </div>
    <!-- 公司文本简介 -->
    <div class="introduction-content">
      <div class="introduction-content-title">公司简介</div>
      <p class="introduction-content-text">
        全球蛙是一家专注为零售商、品牌商、产业带提供全域全渠道数智化增长解决方案的数字商务企业。通过为三二一产（零售商·品牌商·产业带）提供独具创新价值的三融五通六赋能的数智化增长解决方案（三融-线上线下融合·公域私域融合·品牌渠道融合/五通-商品通·营销通·导购通·会员通·订单通/六赋能-技术·数据·运营·营销·供应链·造品），助力三二一产重构销·供·产价值链，促进零售商、品牌商、产业带流通效率提升，全渠道生意增长！
      </p>
    </div>
    <!-- 战略布局 -->
    <div class="strategy">
      <div class="strategy-title">战略布局</div>
      <div class="strategy-sub-title">
        加速数字经济产业集聚，探索区域经济新建设
      </div>
      <div class="strategy-content-company-container">
        <p class="strategy-content">
          全球蛙共设有山西、广东、四川、福建、重庆、山东六个分公司，11个办事处，在全国拥有11大售后服务中心，500余家核心授权服务商和500余家服务网点，可为全国终端客户提供实时、专业、高效的技术保障和服务。
        </p>
        <div class="strategy-company-container">
          <img :src="src" v-for="src in company" :key="src" />
        </div>
      </div>
    </div>
    <!-- 使命、愿景 -->
    <div class="mission-vision-contaoner">
      <div class="mission-vision" v-for="mv in missionVision" :key="mv.id">
        <div class="mv-title">{{ mv.title }}</div>
        <div class="mv-content">{{ mv.content }}</div>
        <img class="mv-image" :src="mv.src" alt="" />
      </div>
    </div>
    <!-- 企业荣誉 -->
    <div class="corporate-honors-container">
      <div class="corporate-honors-title-content-container">
        <div class="corporate-honors-title">企业荣誉</div>
        <div class="corporate-honors-content">硕果累累 成绩斐然</div>
      </div>
      <div class="honors-image-container">
        <div class="honors-image-box">
          <img
            class="honors-image-item"
            v-for="honorsSrc in honorsImages"
            :src="honorsSrc"
            :key="honorsSrc"
          />
        </div>
      </div>
      <div class="article-container">
        <div
          class="article-item"
          v-for="articleItem in article"
          :key="articleItem"
        >
          <i></i>
          <span>{{ articleItem }}</span>
        </div>
      </div>
    </div>
    <contact-us />
    <action-sheet :menu="enumerableRoutes" />
  </div>
</template>

<script>
import { throttle } from "lodash";
import enumerableRoutesMixin from "@/mixins/enumerableRoutes";
export default {
  mixins: [enumerableRoutesMixin],
  data: () => ({
    itemWidth: 130,
    honorIndex: 1,
    left: 0,
    preLeft: 0,
    company: [
      require("@/image/about-us/img_sx_adress_us@3x.png"),
      require("@/image/about-us/img_gd_adress_us@3x.png"),
      require("@/image/about-us/img_sc_adress_us@3x.png"),
      require("@/image/about-us/img_fj_adress_us@3x.png"),
      require("@/image/about-us/img_cq_adress_us@3x.png"),
      require("@/image/about-us/img_sd_adress_us@3x.png"),
    ],
    missionVision: [
      {
        id: 1,
        title: "使命",
        content: "用数智化赋能助力零售商·品牌商·产业带全域全渠道生意增长！",
        src: require("@/image/about-us/img_mission_us@3x.png"),
      },
      {
        id: 2,
        title: "愿景",
        content:
          "携手万家商超 · 万家厂牌 · 百万导购为亿万用户提供高品质高性价比的全球好货和专业贴心的全渠道服务！",
        src: require("@/image/about-us/img_vision_us@3x.png"),
      },
    ],
    honorsImages: [
      require("@/image/about-us/img_honor2_us@3x.png"),
      require("@/image/about-us/img_honor1_us@3x.png"),
      require("@/image/about-us/img_honor3_us@3x.png"),
      require("@/image/about-us/img_honor4_us@3x.png"),
      require("@/image/about-us/img_honor5_us@3x.png"),
      require("@/image/about-us/img_honor6_us@3x.png"),
      require("@/image/about-us/img_honor7_us@3x.png"),
      require("@/image/about-us/img_honor8_us@3x.png"),
      require("@/image/about-us/img_honor9_us@3x.png"),
      require("@/image/about-us/img_honor10_us@3x.png"),
      require("@/image/about-us/img_honor11_us@3x.png"),
    ],
    article: [
      "国家商务部·国家级电子商务示范企业",
      "国家商务部·数字商务企业",
      "国家科技部·高新技术企业",
      "山西民营瞪羚企业",
      "山西电子商务示范企业",
      "山西互联网企业20强",
      "CCFA·中国零售科技新锐企业",
      "CCFA·供应链最佳实践奖",
      "CCFA·最佳平台零售创新优秀案例",
      "联商网·中国零售优秀服务商",
      "国家商务部·商业科技创新优秀案例",
      "中欧商学院·去中心化产业互联网平台案例",
      "全国MBA教指委·第十二届“全国百篇优秀管理案例”",
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variable.global.scss";
.about-us {
  background-color: $bgColorfff;
  .introduction-image {
    background-color: $bgColorfff;
    padding: 64px 30px 60px 30px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 360px;
    }
  }
  .introduction-content {
    background-color: $bgColorfff;
    padding: 0 30px 60px 30px;
    .introduction-content-title {
      color: $textColor222;
      font-size: 40px;
      font-family: Alibaba PuHuiTi-Bold;
      margin-bottom: 40px;
    }
    .introduction-content-text {
      font-size: 26px;
      font-family: PingFang SC-Regular;
      color: $textColor666;
      margin: 0;
      line-height: 52px;
    }
  }
  .strategy {
    width: 750px;
    height: 1000px;
    background: linear-gradient(180deg, #eff5ff, #e4f3e9);
    border-radius: 48px 48px 0px 0px;
    padding: 40px 0 60px 0;
    box-sizing: border-box;
    .strategy-title {
      color: $textColor222;
      font-size: 40px;
      font-family: Alibaba PuHuiTi-Bold;
      margin-bottom: 10px;
      padding-left: 40px;
      box-sizing: border-box;
    }
    .strategy-sub-title {
      color: $textColor999;
      font-size: 28px;
      font-family: PingFang SC-Regular;
      margin: 0;
      padding-left: 40px;
      box-sizing: border-box;
    }
    .strategy-content-company-container {
      padding: 0 30px;
      box-sizing: border-box;
      margin-top: 40px;
      .strategy-content {
        font-size: 26px;
        font-family: PingFang SC, PingFang SC-Regular;
        color: $textColor999;
        line-height: 52px;
        margin: 0;
        margin-bottom: 60px;
      }
      .strategy-company-container {
        img {
          width: 330px;
          height: 116px;
          margin-bottom: 40px;
        }
        img:nth-of-type(odd) {
          margin-right: 30px;
        }
      }
    }
  }
  .mission-vision-contaoner {
    padding: 0 30px;
    margin-bottom: 80px;
    box-sizing: border-box;
    background-color: $bgColorfff;
    .mission-vision {
      .mv-title {
        margin: 60px 0 10px 0;
        color: $textColor222;
        font-size: 40px;
        font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Bold;
      }
      .mv-content {
        color: $textColor999;
        font-size: 28px;
        font-family: PingFang SC, PingFang SC-Regular;
        line-height: 44px;
      }
      .mv-image {
        width: 100%;
        height: 300px;
        margin-top: 40px;
      }
    }
  }
  .corporate-honors-container {
    width: 750px;
    height: 1550px;
    background-color: $bgColorf5;
    border-radius: 48px 48px 0 0;
    box-sizing: border-box;
    .corporate-honors-title-content-container {
      padding: 50px 30px 0 30px;
      box-sizing: border-box;
      .corporate-honors-title {
        color: $textColor222;
        font-size: 40px;
        font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Bold;
        line-height: 54px;
      }
      .corporate-honors-content {
        color: $textColor999;
        font-size: 28px;
        font-family: PingFang SC, PingFang SC-Regular;
        line-height: 44px;
        margin: 10px 0 80px 0;
      }
    }
    .honors-image-container {
      overflow: hidden;
      .honors-image-box {
        display: flex;
        flex-wrap: nowrap;
        -webkit-animation: 30s rowleft linear infinite normal;
        animation: 30s rowleft linear infinite normal;
        .honors-image-item {
          width: 230px;
          height: 160px;
          margin-right: 40px;
        }
      }
    }
    .article-container {
      margin-top: 100px;
      .article-item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        i {
          display: inline-block;
          width: 12px;
          height: 12px;
          background: linear-gradient(90deg, #031bdc 0%, #10ff8e 100%);
          border-radius: 50%;
          margin: 0 12px 4px 30px;
        }
        span {
          font-size: 26px;
          font-family: PingFang SC, PingFang SC-Regular;
          color: $textColor333;
          line-height: 36px;
        }
      }
      .article-item:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
@keyframes rowleft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-2180px, 0, 0);
    transform: translate3d(-2180px, 0, 0);
  }
}
</style>
